import { PLAYLIST_EXTENSION } from '@/constants'

function cyrillicNumericEtc (value) {
  return /^[а-я\w\s]*$/i.test(value)
}

function username (value) {
  return /^[а-я\w\s_.]*$/i.test(value)
}

function phone (value) {
  return /^[\d\-\s)}+]*$/.test(value)
}

function latinNumeric (value) {
  return /^[a-z\d]*$/i.test(value)
}

function filename (value) {
  return !!(!value || value.match(/^[^?*\\/"|']+$/))
}

function isLinkValid (link) {
  link = link.toLowerCase()
  return !!(!link || link.match(/^[^а-я\s]+$/) && link.endsWith(PLAYLIST_EXTENSION))
}

function positive (value) {
  value = Number(value)
  return Number.isInteger(value) && value > 0
}

// name for iam group or role
function validIamName (val) {
  return /^[a-z\w\s]*$/i.test(val)
}

function isValidS3Key (key) {
  const s3KeyRegex = /^[^\\<>%#&{}|^~[\]`]+$/
  return s3KeyRegex.test(key)
}

export {
  positive,
  filename,
  cyrillicNumericEtc,
  latinNumeric,
  phone,
  username,
  isLinkValid,
  validIamName,
  isValidS3Key
}
