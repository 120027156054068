import axios from 'axios'
import user from '../data/user'
import router from '../router'
import stubs from '../stubs'

const refreshDelay = 720000
const instance = axios.create()

const AUTH_SERVICE_URL = process.env.VUE_APP_AUTH_SERVICE_URL
const FILESPOT_SERVICE_URL = process.env.VUE_APP_FILESPOT_SERVICE_URL
const API_SERVICE_URL = process.env.VUE_APP_API_SERVICE_URL
const RECORDER_SERVICE_URL = process.env.VUE_APP_RECORDER_SERVICE_URL
const CDN_STATS_URL = process.env.VUE_APP_CDN_STATS_URL
const IAM_SERVICE_URL = process.env.VUE_APP_IAM_SERVICE_URL
const DASHBOARD_SERVICE_URL = process.env.VUE_APP_DASHBOARD_SERVICE
const CDNVIDEO_URL = process.env.VUE_APP_CDNVIDEO_API_SERVICE

const CONTAINER_ID = '{container_id}'
const USER_ID = '{userId}'
const SUBUSER_ID = '{subuserId}'

const config = {
  login: {
    url: `${IAM_SERVICE_URL}/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  refresh: {
    url: `${IAM_SERVICE_URL}/token/refresh`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  registration: {
    url: `${AUTH_SERVICE_URL}/autoreg`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  restore: {
    url: `${AUTH_SERVICE_URL}/pwrecovery`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  pwrecovery: {
    url: `${AUTH_SERVICE_URL}/pwrecovery`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  confirmRegister: {
    url: `${AUTH_SERVICE_URL}/autoreg/confirm`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  recaptcha: {
    url: `${AUTH_SERVICE_URL}/recaptcha`
  },

  // Users
  getUsers: {
    url: `${AUTH_SERVICE_URL}/api/user`
  },
  getUserData: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}`
  },
  getUserProfile: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/profiles`
  },
  changeUserProfile: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/profiles`,
    method: 'patch'
  },
  changeUserPassword: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/password`,
    method: 'patch'
  },

  // Subusers
  createSubuser: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers`,
    method: 'post'
  },
  getSubusers: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers`
  },
  getSubuserData: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}`
  },
  changeSubuser: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}`,
    method: 'patch'
  },
  deleteSubuser: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}`,
    method: 'delete'
  },
  createSubuserProfile: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}/profiles`,
    method: 'post'
  },
  getSubuserProfile: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}/profiles`,
    method: 'get'
  },
  changeSubuserProfile: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}/profiles`,
    method: 'patch'
  },
  changeSubuserPassword: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/subusers/${SUBUSER_ID}/password`,
    method: 'patch'
  },
  generateS3Credentials: {
    url: `${IAM_SERVICE_URL}/1/users/${USER_ID}{paramSubuser}/s3-credentials`,
    method: 'post'
  },

  getObjects: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/object{path}?strong`
  },
  getObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/object_id/{id}`
  },
  removeObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/object{path}`,
    method: 'delete'
  },
  uploadObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/object{path}`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  downloadObject: {
    url: `${API_SERVICE_URL}/1/download`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  getDownloadObjects: {
    url: `${API_SERVICE_URL}/1/download_tasks`
  },
  updateObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/object{path}`,
    method: 'put'
  },
  createFolder: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/object{path}?dir`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  findObjects: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_ID}/search`
  },
  getContainer: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container`
  },
  getTempLinks: {
    url: `${API_SERVICE_URL}/1/temp?object_id={fileId}`
  },
  createTempLink: {
    url: `${API_SERVICE_URL}/1/temp`,
    method: 'post'
  },
  updateTempLink: {
    url: `${API_SERVICE_URL}/1/temp/{id}`,
    method: 'post'
  },
  removeTempLink: {
    url: `${API_SERVICE_URL}/1/temp/{id}`,
    method: 'delete'
  },
  getPlayers: {
    url: `${API_SERVICE_URL}/1/players`
  },
  getTranscoderPreset: {
    url: `${API_SERVICE_URL}/1/transcoder/presets/{id}`
  },
  getTranscoderPresets: {
    url: `${API_SERVICE_URL}/1/transcoder/presets`
  },
  getTranscoderTasks: {
    url: `${API_SERVICE_URL}/1/transcoder_tasks?{query}`
  },
  transcode: {
    url: `${API_SERVICE_URL}/1/transcoder/{id}`,
    method: 'post'
  },
  transcodeHls: {
    url: `${API_SERVICE_URL}/1/transcoder/hls/{id}`,
    method: 'post'
  },
  getTranscoderStats: {
    url: `${API_SERVICE_URL}/1/transcoder/stat`
  },
  createStream: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}`,
    method: 'post'
  },
  getStreams: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}`
  },
  getStream: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{id}`
  },
  removeStream: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{id}`,
    method: 'delete'
  },
  updateStream: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{id}`,
    method: 'put'
  },
  startInstantRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{id}/start`,
    method: 'post'
  },
  getRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{streamId}/record/{recordId}`
  },
  createRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{streamId}/record`,
    method: 'post'
  },
  updateRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{streamId}/record/{recordId}`,
    method: 'put'
  },
  getRecords: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{streamId}/record`
  },
  removeRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{streamId}/record/{recordId}`,
    method: 'delete'
  },
  stopRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_ID}/{streamId}/record/{recordId}/stop`,
    method: 'post'
  },
  deleteTask: {
    url: `${API_SERVICE_URL}/1/{category}/{id}`,
    method: 'delete'
  },
  getTasks: {
    url: `${API_SERVICE_URL}/1/{category}`
  },
  getTask: {
    url: `${API_SERVICE_URL}/1/{category}/{id}`
  },

  sendFeedback: {
    url: `${AUTH_SERVICE_URL}/api/feedback`,
    method: 'post'
  },
  postCutVideoTask: {
    url: `${API_SERVICE_URL}/1/tasks/cmd/transcode`
  },

  // Player branding
  brandCreate: {
    url: 'https://api.platformcraft.ru/brands',
    method: 'post'
  },
  brandsGet: {
    url: 'https://my.platformcraft.ru/brands',
    method: 'get'
  },
  brandGet: {
    url: 'https://api.platformcraft.ru/brands/{id}',
    method: 'get'
  },
  brandChange: {
    url: 'https://api.platformcraft.ru/brand/{id}',
    method: 'put'
  },
  brandDelete: {
    url: 'https://api.platformcraft.ru/brand/{id}',
    method: 'delete'
  },

  // CDN Stats
  getCdnToken: {
    url: `${CDN_STATS_URL}/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getCdnStats: {
    url: `${CDN_STATS_URL}/`
  },
  getCdnAccounts: {
    url: `${CDN_STATS_URL}/accounts`
  },
  getCdnResources: {
    url: `${CDN_STATS_URL}/resources`
  },
  getCdnStatsByTime: {
    url: `${CDN_STATS_URL}/times`
  },
  getCdnStatsByBrowsers: {
    url: `${CDN_STATS_URL}/browsers`
  },
  getCdnStatsByCodes: {
    url: `${CDN_STATS_URL}/times/codes`
  },
  getCdnStatsByReferrers: {
    url: `${CDN_STATS_URL}/referrers`
  },
  getCdnStatsByPlatforms: {
    url: `${CDN_STATS_URL}/platforms`
  },
  getCdnStatsByRegions: {
    url: `${CDN_STATS_URL}/regions`
  },
  getCdnStatsByCountries: {
    url: `${CDN_STATS_URL}/countries`
  },
  getCdnStatsByHosts: {
    url: `${CDN_STATS_URL}/hosts`
  },

  // Groups
  getGroups: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/groups`
  },
  createGroup: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/groups`,
    method: 'post'
  },
  getGroup: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/groups/{groupId}`
  },
  changeGroup: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/groups/{groupId}`,
    method: 'patch'
  },
  deleteGroup: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/groups/{id}`,
    method: 'delete'
  },

  // Roles
  createRole: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/roles`,
    method: 'post'
  },
  getRoles: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/roles`
  },
  getRole: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/roles/{id}`
  },
  changeRole: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/roles/{id}`,
    method: 'patch'
  },
  deleteRole: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/roles/{id}`,
    method: 'delete'
  },

  // Policies
  createPolicy: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies`,
    method: 'post'
  },
  getPoliciesInsideAccount: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies`
  },
  getSubuserPolicies: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies?principal=subuser:${SUBUSER_ID}`
  },
  getPolicy: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies/{id}`
  },
  changePolicy: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies/{id}`,
    method: 'patch'
  },
  deletePolicy: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies/{id}`,
    method: 'delete'
  },
  getAccountServices: {
    url: `${IAM_SERVICE_URL}/services`
  },
  getAccountPolicy: {
    url: `${IAM_SERVICE_URL}/users/${USER_ID}/policies/account`
  },

  // dashboard
  getUserAccess: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}/access`
  },
  getSubuserAccess: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}/subusers/${SUBUSER_ID}/access`
  },
  getUserSettings: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}/settings`
  },
  getSubuserSettings: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}/subusers/${SUBUSER_ID}/settings`
  },
  setDefaultPrivateFiles: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}{subuserParam}/settings/default-private-files/{value}`, // value = enable | disable
    method: 'post'
  },
  setDefaultAutotranscoding: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}{subuserParam}/settings/autotranscoding/{value}`, // value = enable | disable
    method: 'post'
  },
  setHomeDir: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}/subusers/${SUBUSER_ID}/settings/filespot/containers/${CONTAINER_ID}/home_dir`,
    method: 'post'
  },
  changeAutotranscodingSettings: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${USER_ID}{subuserParam}/settings/autotranscoding`,
    method: 'post'
  },
  getS3Stats: {
    url: `${DASHBOARD_SERVICE_URL}/1/stats/users/${USER_ID}/services/s3/space`
  },

  // CDNVideo
  addToCDNCache: {
    url: `${CDNVIDEO_URL}/1/users/${CONTAINER_ID}/cache`,
    method: 'put'
  },
  clearCDNCache: {
    url: `${CDNVIDEO_URL}/1/users/${CONTAINER_ID}/cache`,
    method: 'delete'
  }
}

const handlers = {
  success: [],
  error: []
}

let refreshLoading = false

const api = {
  addHandler (handler, type) {
    handlers[type].push(handler)
    return this
  },
  onSuccess (response) {
    let breakChain = false

    handlers.success.forEach(handler => {
      if (handler(response) === false) {
        breakChain = true
      }
    })

    return breakChain ? (new Promise(() => {})) : response
  },
  onError (error) {
    let breakChain = false

    handlers.error.forEach(handler => {
      if (handler(error) === false) {
        breakChain = true
      }
    })

    if (breakChain) {
      return new Promise(() => {})
    } else {
      throw error
    }
  },
  token: null,
  refreshToken () {
    const token = localStorage.getItem('refresh_token')
    const id = localStorage.getItem('user_id')

    if (!token || !id) {
      return new Promise((resolve, reject) => {
        reject(new Error('Refresh info is not found'))
      })
    }

    return this.refresh({
      user_id: id,
      refresh_token: token
    }).then(res => {
      setTimeout(() => {
        this.refreshToken()
      }, refreshDelay)

      return this.setToken(res.data)
    })
  },
  async setToken (data) {
    this.token = data.access_token
    user.id = data.subuser_id || data.user_id

    localStorage.setItem('refresh_token', data.refresh_token)
    localStorage.setItem('user_id', data.subuser_id || data.user_id)
    localStorage.setItem('expires_at', data.expires_at)

    const userId = data.user_id
    const subuserId = user.id
    const isOwner = userId === subuserId
    const method = isOwner ? this.getUserData : this.getSubuserData

    return await method({ userId, subuserId }).then(({ data }) => {
      this.loadProfile(userId, subuserId)

      if (!data.id) {
        data.id = data.user_id
      }

      user.setUserData(data)
    }).then(() => {
      const method = isOwner ? this.getUserAccess : this.getSubuserAccess
      return method({ userId, subuserId }).then(({ data }) => {
        user.setUserAccess(data)
      })
    }).then(() => {
      const method = isOwner ? this.getUserSettings : this.getSubuserSettings
      return method({ subuserId }).then(({ data }) => {
        user.setUserSettings(data)
      })
    })
  },
  async loadProfile (userId, subuserId) {
    const method = userId === subuserId ? this.getUserProfile : this.getSubuserProfile
    await method({ userId, subuserId }).then(({ data }) => {
      user.setUserProfile(data)
    })
  },
  logout () {
    this.token = null
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('CDN_TOKEN')
    localStorage.removeItem('CDN_TOKEN_LIVE_TIME')
    localStorage.removeItem('session_key')
    user.id = null
    user.user_id = null
    router.push({
      name: 'login'
    })
  },
  async checkExpiresAt () {
    const expiresAt = localStorage.getItem('expires_at')
    const refresh = localStorage.getItem('refresh_token')

    if (refresh && ((Math.floor(expiresAt / 1000000) - (2678400 * 2)) < +new Date())) {
      if (refreshLoading) return
      refreshLoading = true

      await axios({
        method: 'post',
        url: `${IAM_SERVICE_URL}/token/refresh`,
        data: {
          user_id: localStorage.getItem('user_id'),
          refresh_token: refresh
        }
      }).then(({ data }) => {
        localStorage.setItem('refresh_token', data.refresh_token)
        localStorage.setItem('expires_at', data.expires_at)
      }).finally(() => {
        refreshLoading = false
      })
    }
  }
}

Object.keys(config).forEach(function (method) {
  const endpoint = config[method]

  api[method] = function (data, params = {}) {
    const config = Object.assign({}, params, {
      url: endpoint.url,
      method: endpoint.method || 'get',
      headers: endpoint.headers || {}
    })

    if (user.user_id && config.url.match(USER_ID)) {
      config.url = config.url.replace(USER_ID, user.user_id)
    }

    if (config.url.match(CONTAINER_ID)) {
      config.url = config.url.replace(CONTAINER_ID, user.user_id)
    }

    if (data instanceof FormData) {
      for (const item of data.entries()) {
        const [key, value] = item
        config.url = config.url.replace('{' + key + '}', () => {
          data.delete(key)
          return value
        })
      }
    } else if (data instanceof Object) {
      Object.keys(data).forEach((item) => {
        config.url = config.url.replace('{' + item + '}', () => {
          const value = data[item]
          delete data[item]
          return value
        })
      })
    }

    if (config.method === 'get') {
      config.params = data
    } else {
      if (data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        const query = new URLSearchParams()

        Object.keys(data).forEach(function (key) {
          query.append(key, data[key])
        })
        config.data = query
      } else {
        config.data = data
      }
    }

    if (endpoint.stub) {
      return new Promise(resolve => {
        const response = stubs[endpoint.url]

        if (typeof response === 'function') {
          resolve({ data: response() })
        } else {
          throw new Error(endpoint.url)
        }
      })
    }

    return request(config)
  }
})

function request (params) {
  api.checkExpiresAt()

  if (api.token) {
    params.headers = params.headers || {}
    Object.assign(params.headers, {
      Authorization: `Bearer ${api.token}`
    })
  }

  params.url = encodeURI(params.url)

  return instance.request(params)
    .then(api.onSuccess)
    .catch(api.onError)
}

export default api
