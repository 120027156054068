import { ref } from 'vue'
import { defineStore } from 'pinia'
import { S3_POLICY_VERSION } from '@/configs/config'

export const useS3SettingsStore = defineStore('s3SettingsStore', {
  state: () => {
    const bucket = ref({
      name: '',
      createAt: '',
      endpoint: '',
      changedAt: undefined,
      type: undefined,
      size: undefined,
      key: undefined,
      CORSRules: [],
      policy: {
        Version: S3_POLICY_VERSION,
        Statement: []
      }
    })

    const setDefaultBucket = () => {
      bucket.value = {
        name: '',
        createAt: '',
        endpoint: '',
        changedAt: undefined,
        type: undefined,
        size: undefined,
        key: undefined,
        CORSRules: [],
        policy: {
          Version: S3_POLICY_VERSION,
          Statement: []
        }
      }
    }

    const setBucketName = (name) => {
      bucket.value.name = name
    }

    return {
      bucket,
      setBucketName,
      setDefaultBucket
    }
  }
})
